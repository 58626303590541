import React from 'react'

class Features extends React.Component {
  render () {
    return (
      <section id='features'>
        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>
            <h5>Features</h5>
            <h1>Great features you'll love.</h1>
            <p className='lead'>Following are some of the features of our RoboChef.</p>
          </div>
        </div>

        <div className='row features-content'>
          <div className='features-list block-1-3 block-s-1-2 block-tab-full group'>
            <div className='bgrid feature'>
              <span className='icon'><i className='icon-layers' /></span>
              <div className='service-content'>
                <h3 className='h05'>On-Demand Cooking</h3>
              </div>
            </div>
            <div className='bgrid feature'>
              <span className='icon'><i className='icon-layers' /></span>
              <div className='service-content'>
                <h3 className='h05'>Fully Automated</h3>
              </div>
            </div>
            <div className='bgrid feature'>
              <span className='icon'><i className='icon-layers' /></span>
              <div className='service-content'>
                <h3 className='h05'>Zero Man-Power</h3>
              </div>
            </div>
            <div className='bgrid feature'>
              <span className='icon'><i className='icon-layers' /></span>
              <div className='service-content'>
                <h3 className='h05'>High Availability</h3>
              </div>
            </div>
            <div className='bgrid feature'>
              <span className='icon'><i className='icon-file' /></span>
              <div className='service-content'>
                <h3 className='h05'>IoT metrics</h3>
              </div>
            </div>
            <div className='bgrid feature'>
              <span className='icon'><i className='icon-eye' /></span>
              <div className='service-content'>
                <h3 className='h05'>24/7 Monitoring</h3>
              </div>
            </div>
            <div className='bgrid feature'>
              <span className='icon'><i className='icon-paint-brush' /></span>
              <div className='service-content'>
                <h3 className='h05'>Intelligent & Hygenic</h3>
              </div>
            </div>
            <div className='bgrid feature'>
              <span className='icon'><i className='icon-list' /></span>
              <div className='service-content'>
                <h3 className='h05'>Predictive Maintenance</h3>
              </div>
            </div>
            <div className='bgrid feature'>
              <span className='icon'><i className='icon-window' /></span>
              <div className='service-content'>
                <h3 className='h05'>Remote Control</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Features
