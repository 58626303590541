import React from 'react'

class CTA extends React.Component {
  render () {
    return (
      <section id='cta'>
        <div className='row cta-content'>
          <div className='col-twelve'>
            <h1 className='h01'>Order Now. Try RoboChef.</h1>
            <p className='lead'>Download the app now. Available on the:</p>
            <ul className="download-badges">
              <li><a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/robotic-kitchen/id1456569907" title="" className="badge-appstore"  data-aos="fade-up">App Store</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=in.admatic.robochef&hl=en" title="" className="badge-googleplay" data-aos="fade-up">Play Store</a></li>
            </ul>
          </div>
        </div>

      </section>
    )
  }
}

export default CTA
