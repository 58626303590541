import React from 'react'

class Solution extends React.Component {
  render () {
    return (
      <section id='features-1'>
        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>
            <h5>Solution</h5>
            <h1>What is the Solution?.</h1>
            <p className='lead'>RoboChef can solve your problems through.</p>
          </div>
        </div>

        <div className='row features-content'>
          <div className='features-list block-1-3 block-s-1-2 block-tab-full group'>
            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <br></br>
                <h2 className='h05'>precise measuring cups & sensor fusion technology gives consistent taste.</h2>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <img src="auto.png" style={{backgroundColor: "white", borderRadius: "25px"}} height="200"  width="200"></img>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <br></br>
                <h2 className='h05'>We won’t be needing any cook to perform the cooking process.</h2>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <img src="precise.png" style={{backgroundColor: "white", borderRadius: "25px"}} height="200"  width="200"></img>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <br></br><br></br>
                <h2 className='h05'>CookOps is applying the automation techniques widely adopted in the software world specific to Cooking.</h2>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <img src="noman.png" style={{backgroundColor: "white", borderRadius: "25px"}} height="200"  width="200"></img>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <br></br>
                <br></br>
                <h2 className='h05'>Zero CO2 emissions & Safest Kitchen</h2>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <img src="safe.png" style={{backgroundColor: "white", borderRadius: "25px"}} height="200"  width="200"></img>
              </div>
            </div>

            <div className='bgrid feature'>
              <span className='icon'><i className='icon-feature' /></span>
              <div className='service-content'>
                <br></br>
                <br></br>
                <h2 className='h05'>We Track and Visualize everything from Procurement to Sales.</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Solution
