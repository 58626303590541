import React from 'react'

class Solution extends React.Component {
  render () {
    return (
      <section id='features-2'>
        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>
            {/* <h5>RoboChef Flow</h5> */}
            <h1 style={{color: "white"}}>RoboChef Flow</h1>
            <p className='lead' style={{color: "grey"}}>The easy flow of Robochef</p>
          </div>
        </div>
        
        <div className='row features-content'>
        <img src="images/robochef-flow.jpg" alt="RoboChef Flow" style={{width: "100%", height: "auto"}}/>
        </div>
            
      </section>
    )
  }
}

export default Solution
