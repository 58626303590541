import React from 'react'

class Navigate extends React.Component {
  render () {
    return (
      <section id='process-2'>
        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>
            <h5>Design Now!</h5>
            <h1>Choose your Option</h1>
            {/* <p className='lead'>It's Easy to get along with RoboChef. It can cook you tasty food with consistent taste.</p> */}
          </div>
        </div>

        <div className='row process-content'>
          <a target="_blank" href="/plan-your-automated-kitchen"><div className='left-side-1'>
            <br></br>
            <img src="images/plan.png" height="300px"></img>
            <h2>Plan your Robochef</h2>
          </div></a>



          <a target="_blank" href="/roi-calculator"><div className='right-side-1'>
          <img src="images/calc.png" ></img>
          <br></br>
          <h2>RoboChef ROI Calculator</h2>
          </div></a>
          
          <a target="_blank" href="/design"><div className='middle-side-1'><div className='image-part-2'/><br></br><h2>Design your Robochef</h2></div></a>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </section>
    )
  }
}

export default Navigate
