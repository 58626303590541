import React from 'react'

class Awards extends React.Component {
  render () {
    return (
      <section id='pricing1'>

        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>

            {/* <h5>Our Pricing</h5> */}
            <br></br><br></br>
            <h1>RoboChef Gallery</h1>

            <p className='lead'>RoboChef and It's Journey.</p>

          </div>
        </div>

        <div class="row pricing-content">

         <div class="pricing-tables block-1-2 group">

            <div class="bgrid">

                  <div class="price-block-gallery">

                      <img loading="lazy" src="/images/awards/4.jpg" alt="Tech Crunch Germany Robochef" height="100%" width="100%" />
                      <h3 class="plan-title">TechCrunch Global TopPick 2019</h3>
                      <p>Berlin Germany</p>
              

                  </div>

			      </div>

			      <div class="bgrid">

               <div class="price-block-gallery">

                  <img loading="lazy" src="/images/awards/2.jpg" alt="Gitex Dubai Robochef" height="100%" width="100%"/>
	            		<h3 class="plan-title">Gitex Dubai 2019</h3>
                  <p>India Innovation Cup Winner</p>

            	</div>

			   </div> 

         <div class="bgrid">

               <div class="price-block-gallery">

                  <img loading="lazy" src="/images/awards/1.jpeg" alt="Demoday Bangalore Robochef" height="100%" width="100%"/>
	            		<h3 class="plan-title">DemoDay Best Startup Title Award Winner</h3>
                  <p>StartupClub , Bangalore</p>

            	</div>

			   </div> 

         <div class="bgrid">

               <div class="price-block-gallery">

                  <img loading="lazy" src="/images/awards/3.jpg" alt="Best Startup Robochef" height="100%" width="100%"/>
	            		<h3 class="plan-title">Best Startup Award</h3>
                  <p>SAI Event</p>

            	</div>

			   </div> 


         </div>

      </div> 
      </section>
    )
  }
}

export default Awards
