import React from 'react'

class Solution extends React.Component {
  render () {
    return (
      <section id='features-2'>
        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>
            {/* <h5>RoboChef Flow</h5> */}
            <h1 style={{color: "white"}}>Models</h1>
            <p className='lead' style={{color: "grey"}}>Kitchen Ecosystem by RoboChef</p>
          </div>
        </div>
        
        <div class="row pricing-content">

        <div class="pricing-tables block-1-2 group">

          <div class="bgrid">

                <div class="price-block-gallery">

                    <img loading="lazy" src="/images/models/fry.jpg" alt="Tech Crunch Germany Robochef" height="100%" width="100%" />
                    <h3 class="plan-title">Automatic Frying System</h3>
                    <p></p>
            

                </div>

          </div>

          

          <div class="bgrid">

              <div class="price-block-gallery" >

                <img loading="lazy" src="/images/models/wok2.jpg" alt="Gitex Dubai Robochef" style={{ height: "10%", width: "100%"}} />
                <h3 class="plan-title">Automated Wok System</h3>
                <p></p>

            </div>

        </div> 

        <div class="bgrid">

              <div class="price-block-gallery">

                <img loading="lazy" src="/images/models/dosa.jpg" alt="Demoday Bangalore Robochef" height="100%" width="100%"/>
                <h3 class="plan-title">Automatic Dosa System</h3>
                {/* <p>StartupClub , Bangalore</p> */}

            </div>

        </div> 

        

        <div class="bgrid">

              <div class="price-block-gallery">

                <img loading="lazy" src="/images/models/chapati.jpeg" alt="Best Startup Robochef" height="100%" width="100%"/>
                <h3 class="plan-title">Automatic Chapati System</h3>
                {/* <p>SAI Event</p> */}

            </div>

        </div> 

        <div class="bgrid">

              <div class="price-block-gallery">

                <img loading="lazy" src="/images/models/idly.jpg" alt="Best Startup Robochef" height="100%" width="100%"/>
                <h3 class="plan-title">Automatic Idly System</h3>
                {/* <p>SAI Event</p> */}

            </div>

        </div> 

        <div class="bgrid">

              <div class="price-block-gallery">

                <img loading="lazy" src="/images/models/veg.jpg" alt="Best Startup Robochef" height="100%" width="100%"/>
                <h3 class="plan-title">Automatic Vegetable Chopper</h3>
                {/* <p>SAI Event</p> */}

            </div>

        </div> 


        </div>

        </div> 
            
      </section>
    )
  }
}

export default Solution
