import React from 'react'

class Problems extends React.Component {
  render () {
    return (
      <section id='process-1'>
        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>
            <h5>Problems</h5>
            <h1>What are the Problems faced by People?</h1>
            {/* <p className='lead'>It's Easy to get along with RoboChef. It can cook you tasty food with consistent taste.</p> */}
          </div>
        </div>

        <div className='row process-content'>
          <div className='left-side'>
            <div className='item'>
              {/* <h5>Order</h5> */}
              <h2>Consistet taste is not assured even by bigger restaurants</h2>
            </div>

            <br></br><hr></hr><br></br><br></br>

            <div className='item' >
              {/* <h5>Setup RoboChef</h5> */}
              <h2>Food Wastages & Low Profits.</h2>
            </div>

          </div>



          <div className='right-side'>
            <div className='item'>
              {/* <h5>Customize</h5> */}
              <h2>Chef availability and Manpower are difficult these days.</h2>
            </div>

            <br></br><hr></hr><br></br><br></br>

            <div className='item' >
              {/* <h5>Start Cooking</h5> */}
              <h2>High Co2 emissions & Wastage of Ingredients.</h2>
            </div>

          </div>
          <div className='image-part-1' />
          <br></br>
          <br></br>
          <br></br>
        </div>
      </section>
    )
  }
}

export default Problems
