import React from 'react'

import Layout from '../components/layout'
import Intro from '../components/index/intro'
import Product from '../components/index/product'
import Problems from '../components/index/problems'
import Navigate from '../components/index/navigate'
import Solution from '../components/index/solution'
import Flow from '../components/index/flow'
import Process from '../components/index/process'
import Features from '../components/index/features'
import Seenon from '../components/index/seenon'
import Variants from '../components/index/variants'
import Models from '../components/index/models'
import FAQ from '../components/index/faq'
import CTA from '../components/index/cta'
import WP from '../components/index/wp'
import Contact from '../components/index/contact'
import Awards from '../components/index/awards';

export default ({ data }) => {
  return (
    <Layout>
      <Intro />
      <Product />
      <Flow />
      <Problems />
      <Solution />
      <Navigate />
      <Process />
      <Awards />
      <Features />
      <Variants />
      <Models />
      <CTA />
      <Seenon />
      <FAQ />
      <Contact />
      <WP />
    </Layout>
  )
}
