import React from 'react'
// import 'css/seenon.css'
{/* <link rel='stylesheet' href='/css/seenon.css' /> */}

class Problems extends React.Component {
  render () {
    return (
      <section id='process-1'>
        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>
            <h5>Media & News</h5>
            <h1>As Seen on</h1>
            {/* <p className='lead'>It's Easy to get along with RoboChef. It can cook you tasty food with consistent taste.</p> */}
          </div>
        </div>

       
        <div class="banner1">
	    <ul>
	        <li>
		        <div class="client-group govt">
            <div class="one-fourth first">
						<a><div class="client va-logo"></div></a>
					</div>
					<div class="one-fourth">
          <a target="_blank" href="https://www.thehindu.com/life-and-style/food/watch-fully-automated-cooking-thanks-to-robochef/article30025435.ece"><div class="client disa-logo"></div></a>
					</div>
					<div class="one-fourth">
          <a target="_blank" href="https://techcrunch.com/2019/11/12/introducing-the-tc-top-picks-for-disrupt-berlin-2019/"><div class="client centcom-logo"></div></a>
					</div>
					<div class="one-fourth">
          <a target="_blank" href="https://www.gitexfuturestars.com/exhibitors/robochef-the-robotic-kitchen"><div class="client dos-logo"></div></a>
					</div>	
				</div>
			</li>
	        <li>
	        	<div class="client-group commercial">
		        	<div class="one-fourth first">
						  <a target="_blank" href="https://www.youtube.com/watch?v=r57EYBOdJW0"><div class="client graham-holdings-logo"></div></a>
					</div>
					<div class="one-fourth">
          <a target="_blank" href="https://www.youtube.com/watch?v=xk0uUKRYZd4"><div class="client k12-logo"></div></a>
					</div>
					<div class="one-fourth">
          <a target="_blank" href="https://www.youtube.com/watch?v=FdFwdWkmW4k"><div class="client blackboard-logo"></div></a>
					</div>
					<div class="one-fourth">
          <a target="_blank" href="https://yourstory.com/tamil/robochef-indias-first-robot-cooking-machine-chennai-startup"><div class="client kaplan-university-logo"></div></a>
					</div>	
				</div>	        
	        </li>
	    </ul>
		
	</div>
	<br>
	</br>
	<br></br>
	<center><a href="/press"><button class="w3-bar-item w3-button" style={{ width: "33.3%"}}>View More</button></a></center>
      </section>
    )
  }
}

export default Problems
