import React from 'react'

class FAQ extends React.Component {
  render () {
    return (
      <section id='faq-1'>
        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>
            <h5>Faq</h5>
            <h1>Questions and Answers.</h1>
            <p className='lead'>We can clear your Doubts here.</p>
          </div>
        </div>

        <div className='row faq-content'>
          <div className='q-and-a block-1-2 block-tab-full group'>
            <div className='bgrid'>
              <h3>What are the recipes, i need to know about the recipe list?</h3>
              <p>Curretly we have more than 800+ recipes from different cuisines, to know more about the recipes download our app using the links above. </p>
            </div>

            <div className='bgrid'>
              <h3>How to see a Robochef demo? </h3>
              <p>If you are realy interested in Robochef, you can see a demo by just filling up your details in contact form. You can also contact us through call or support chat.</p>
            </div>

            <div className='bgrid'>
              <h3>`Will training be provided before operating?`</h3>
              <p>We provide all the essential training needed to operate the Robochef. It's easy to handle Robochef. Load - Start Cooking - Serve</p>
            </div>

            <div className='bgrid'>
              <h3>What is the basic qualification needed to Operate ?</h3>
              <p>Anyone who can read and write can operate our Robochef. It invloves basic process like loading ingredients in containers and ordering any recipes.</p>
            </div>

            <div className='bgrid'>
              <h3>Can I Customise your Robochef System as per my need ?</h3>
              <p>Yes. Robochef can be Customized as per your need. You can do it by sitting at your place. Just visit the 3D experience in the Product section.</p>
            </div>

            <div className='bgrid'>
              <h3>Is it a safe system to Operate ?</h3>
              <p>Robochef is one of the Safest system to Operate. It uses Induction heating technology for heating efficiently. The system is insulated in a better way so that the heating doesn't affect anything outside the cooking process.</p>
            </div>

            <div className='bgrid'>
              <h3>Can Robochef make tiffin items like Dosa, Idly , Chapati?</h3>
              <p>RoboChef variants listed here will not make tiffin items listed. This is because , Robochef was not designed to make tiffin items. Contact us to know about our other products that can make Idly, Dosa and Chapati.</p>
            </div>

            <div className='bgrid'>
              <h3>Tell me about the Maintenance?</h3>
              <p>Our Service engineeres will visit you regularly for the RoboChef check-up process. To know more about Warranty and other stuff, Contact Us.</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default FAQ
