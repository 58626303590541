import React from 'react'

class Variants extends React.Component {
  render () {
    return (
      <section id='pricing'>

        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>

            <h5>Our Products</h5>
            <h1>RoboChef Variants.</h1>

            <p className='lead'>Following are the Different Series of Robotic Kitchen.</p>

          </div>
        </div>

        <div class="row pricing-content">

         <div class="pricing-tables block-1-4 group">

            <div class="bgrid">

            	<div class="price-block">

            		<div class="top-part">
                  <img loading="lazy" src="images/11.jpg" width="222" height="127" alt="Prawn Cooking Recipe Indian by RoboChef" />
	            		<h3 class="plan-title">D Series</h3>
                  <br></br>
                  <p>Domestic - Home Use, On-Demand cooking</p>
	            	</div>

	               <div class="bottom-part">

	            		<ul class="features">
                  {/* Prawns Masala is a spicy Prawns dish. Royyala means Prawns in Telugu, and Iguru means thick gravy. As you all know, in India, Andhra food is labelled as the spiciest, and this typical Prawns curry. */}
		                  <li><strong>Min 1 - Max 4</strong> Serves</li>
		                  <li><strong>3.7 Litres</strong>Vessel Capacity</li>
		                  <li><strong>1 Recipe only</strong>Parallel Cooking</li>
		               </ul>
                  3D Experience
				  <br></br>
		               <h6>Coming Soon</h6>
					   <br></br>
	            	</div>

            	</div>

			   </div>

            <div class="bgrid">

            	<div class="price-block">

            		{/* <div class="top-part" data-info="recommended"> */}
                <div class="top-part">
                  <img loading="lazy" src="images/11.jpg" width="222" height="127" alt="Coconut Rice Recipe Indian by RoboChef" />
	            		<h3 class="plan-title">AX Series</h3>
                  <br></br>
                  <p>Ala-carte - On-Demand cooking, 24x7 Kitchens</p>
	            	</div>

	               <div class="bottom-part">

	            		<ul class="features">
                  {/* Thengai sadam recipe - light, mild south indian coconut rice recipe made with fresh grated coconut. It is incredibly easy to make, loaded with flavor and great for lunch box. */}
                      <li><strong>Min 1 - Max 4</strong> Serves</li>
		                  <li><strong>2 x 3.7 Litres</strong>Vessel Capacity</li>
		                  <li><strong>2 Recipe only</strong>Parallel Cooking</li>
		               </ul>
                  3D Experience
				  <br></br>
		               <h6 >Coming Soon</h6>
					<br></br>
	            	</div>

            	</div>

			  </div>

           <div class="bgrid">

               <div class="price-block">

            		<div class="top-part">
                  <img loading="lazy" src="images/22.jpg" width="200" height="120" alt="Mix of Bulk and On-demand cooking solutionf" />
	            		<h3 class="plan-title">QX Series</h3>
                  <br></br>
                  <p>QSR model - Mix of Bulk and On-demand cooking solution</p>
	            	</div>

						<div class="bottom-part">

	            		<ul class="features">
                  {/* Egg Kurma is a saucy dish originated in central Asia and is made of spices, meat, vegetables, yogurt, seeds or nut paste. This simple egg kurma is roughly adapted from few of the south indian dishes. */}
		                   <li><strong>Min 2 - Max 100</strong> Serves</li>
		                  <li><strong>2 x 7.7 Litres</strong>Vessel Capacity</li>
		                  <li><strong>2 Recipe only</strong>Parallel Cooking</li>
		               </ul>
                  3D Experience<br></br>
		               <a class="button large" target="_blank" href="/design">Go!</a>


	            	</div>

            	</div>

			   </div>

         <div class="bgrid">

               <div class="price-block" primary>

            		<div class="top-part" data-info="recommended">
                  <img loading="lazy" src="images/robochef_co.jpg" width="222" height="122" alt="Egg Kurma Recipe Indian by RoboChef" />
	            		<h3 class="plan-title">CX Series</h3>
                  <p>Commercial Model - bulk Cooking, Catering units, Large Restaurants, Central Kitchens</p>
	            	</div>

						<div class="bottom-part">

	            		<ul class="features">
                  {/* Egg Kurma is a saucy dish originated in central Asia and is made of spices, meat, vegetables, yogurt, seeds or nut paste. This simple egg kurma is roughly adapted from few of the south indian dishes. */}
		                   <li><strong>Min 20 - Max 1000</strong> Serves</li>
		                  <li><strong>2 x 37 Litres</strong>Vessel Capacity</li>
		                  <li><strong>2 Recipe only</strong>Parallel Cooking</li>
		               </ul>
                  3D Experience<br></br>
		               <a class="button large" target="_blank" href="/design">Go!</a>


	            	</div>

            	</div>

			   </div>

         </div>

      </div> 
      </section>
    )
  }
}

export default Variants
