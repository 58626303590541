import React from 'react'

class Product extends React.Component {
  render () {
    return (
      <section id='faq-1'>
        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>
            <h5>PRODUCT</h5>
            <h1>The Robotic Kitchen</h1>
            <p className='lead'>Fully Automated</p>
          </div>
        </div>

        <div className='row faq-content'>
          <div className='q-and-a block-1-2 block-tab-full group'>
             <center> <h3>RoboChef is the World's First Fully Automated Robotic Kitchen cooking 800+ recipes with ZERO manual effort powered by IoT, Robotics & AI.
</h3>         </center>
            
<br></br><br></br>
             <div className='bgrid'>
            </div>

            <div className='bgrid'>
              <br></br><br></br>
              <ul>
                  <h4><li>Customizable</li>
                  <br></br><li>Consistency</li>
                  <br></br><li>Fully Automated</li>
                  <br></br><li>Record Recipe Once, Cook Forever</li> </h4>
                  </ul>
                  <p></p>
            </div>

            <div className='bgrid'>
            <img src='images/robochef_co.jpg' width='540' height='443' loading="lazy" alt='' />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Product
