import React from 'react'

class WP extends React.Component {
  constructor(props){  
    super(props);  
    this.state = { showPopup: false };  
    }  
    
      togglePopup() {  
    this.setState({  
         showPopup: !this.state.showPopup  
    });  
     }  
  render () {
    return (
      <section id='cta'>
        <div className='row cta-content'>
          <div className='col-twelve'>
            <h1 className='h01'>Download our WhitePaper now.</h1>
            <p className='lead'>Click on the following</p>
            <ul className="download-badges">
              <h3>Community Kitchen - How Automated Cooking robots can revolutionise it </h3> <li><a href="#mailmunch-pop-806282" title="" className="badge-wp" data-aos="fade-up">Download</a></li><br></br><br></br>
              <h3>NEED FOR PERSONALIZED COOKING: Why it is going to cause a revolution in India </h3> <li><a href="#mailmunch-pop-806316" title="" className="badge-wp" data-aos="fade-up">Download</a></li>
            </ul>
          </div>
        </div>
      </section>
    )
  }
}

export default WP
