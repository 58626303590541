import React from 'react'

class Process extends React.Component {
  render () {
    return (
      <section id='process'>
        <div className='row section-intro'>
          <div className='col-twelve with-bottom-line'>
            <h5>Process</h5>
            <h1>How it works?</h1>
            {/* <p className='lead'>It's Easy to get along with RoboChef. It can cook you tasty food with consistent taste.</p> */}
          </div>
        </div>

        <div className='row process-content'>
          <div className='left-side'>
            <div className='item' data-item='1'>
              <h5>Order</h5>
              <p>Design and Order your RoboChef.</p>
            </div>

            <div className='item' data-item='2'>
              <h5>Setup RoboChef</h5>
              <p>We set-up and provide training for your RoboChef.</p>
            </div>

            <div className='item' data-item='3'>
              <h5>Robochef App</h5>
              <p>Choose a Recipe from our App or RoboChef Control Screen.</p>
            </div>
          </div>



          <div className='right-side'>
            <div className='item' data-item='4'>
              <h5>Customize</h5>
              <p>Customize and add recipes to vessel.</p>
            </div>

            <div className='item' data-item='5'>
              <h5>Start Cooking</h5>
              <p>Load the ingredients and select Start Cooking.</p>
            </div>

            <div className='item' data-item='6'>
              <h5>Serve / Deliver</h5>
              <p>Serve to your customers.</p>
            </div>
          </div>
          <div className='image-part' />
        </div>
      </section>
    )
  }
}

export default Process
